
import { IonPage, IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonIcon, IonInput, IonButton, modalController } from "@ionic/vue";

import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { arrowBackOutline, eyeOutline, eyeOffOutline } from "ionicons/icons";

import { openToast } from "@/services/toast";

import apiUser from "@/services/users";

import PwaInstructionsVue from "@/components/PwaInstructions.vue";

export default defineComponent({
    name: "Login",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonIcon,
        IonButton,
        IonInput,
    },
    setup() {
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).users_id;
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;

        const loading = ref(false);
        const setOpen = (state: boolean) => (loading.value = state);

        const router = useRouter();

        function goBack() {
            router.go(-1);
        }

        const currentPassword = ref(null);
        const newPassword = ref(null);
        const confirmNewPassword = ref(null);

        /*
         * Open pwa instruction page
         */
        async function openModal() {
            const modal = await modalController.create({
                component: PwaInstructionsVue,
            });
            return modal.present();
        }

        const showNewPassword = ref(false);
        function toggleShowNewPassword() {
            showNewPassword.value = !showNewPassword.value;
        }

        const showConfirmNewPassword = ref(false);
        function toggleShowConfirmNewPassword() {
            showConfirmNewPassword.value = !showConfirmNewPassword.value;
        }

        const showCurrentPassword = ref(false);
        function toggleShowCurrentPassword() {
            showCurrentPassword.value = !showCurrentPassword.value;
        }

        const loadingChangePassword = ref(false);
        async function changePassword() {
            if (!currentPassword.value || !newPassword.value || !confirmNewPassword.value) {
                openToast("Tutti e tre i campi password devono essere compilati", "toast_danger");
                return;
            }

            // Se password nuova è uguale alla vecchia
            if (currentPassword.value === newPassword.value) {
                openToast("La nuova password non può essere uguale alla vecchia", "toast_danger");
                return;
            }

            // Se password nuova è diversa dalla conferma mostro errore
            if (newPassword.value != confirmNewPassword.value) {
                openToast("Devi inserire la stessa password per confermare", "toast_danger");
                return;
            }

            loadingChangePassword.value = true;
            try {
                console.log("cambio password...");
                const res = await apiUser.setPassword(dipendenteID, currentPassword.value, newPassword.value, confirmNewPassword.value);
                /* const res = await apiUser.test("TEST"); */
                console.log(res);
                if (res.status === 200 && res.data.status === 0) {
                    openToast(`${res.data.txt}`, "toast_danger");
                    return;
                }
                //mostro messaggio e aggiorno dati in localstorage
                if (res.status === 200 && res.data.status === 1) {
                    localStorage.setItem(`${LOCALSTORAGE_KEY}`, JSON.stringify(res.data.data));
                    currentPassword.value = null;
                    newPassword.value = null;
                    confirmNewPassword.value = null;

                    openToast(`${res.data.txt}`, "toast_success");
                    return;
                }
            } catch (error) {
                openToast("Errore durante la modifica della password", "toast_danger");
            } finally {
                loadingChangePassword.value = false;
            }
        }

        return {
            arrowBackOutline,
            loading,
            setOpen,
            openModal,
            //...
            goBack,
            currentPassword,
            newPassword,
            confirmNewPassword,
            eyeOutline,
            eyeOffOutline,
            showNewPassword,
            toggleShowNewPassword,
            showConfirmNewPassword,
            toggleShowConfirmNewPassword,
            showCurrentPassword,
            toggleShowCurrentPassword,
            changePassword,
            loadingChangePassword,
        };
    },
});
