<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-header :translucent="true">
      <ion-toolbar>
          <ion-buttons slot="start">
              <ion-button @click="closeModal()">
                  <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
              </ion-button>
          </ion-buttons>
          <ion-title color="white">Installazione</ion-title>
      </ion-toolbar>
  </ion-header>

      <!--  iOS instructions -->
      <ion-grid class="ion-padding-horizontal" v-show="showIos">
        <ion-row>
          <ion-col size="12">
            <ion-text class="help_text">
              Cliccando sul pulsante evidenziato si aprirà una schermata in cui potrai installare l'applicazione selezionando <span class="text_bold">"Aggiungi alla schermata Home"</span>
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <img src="assets/pwa_instructions/step1_installazione.png" class="img_step1" />
          </ion-col>
          <ion-col size="12">
            <div class="img_container">
              <img src="assets/pwa_instructions/step2_home.png" class="img_step2" />
            </div>
          </ion-col>
          <ion-col size="12">
            <div @click="closeModal" class="btn_close">
              Ok, ho capito
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!--  Android instructions -->
      <ion-grid class="ion-padding-horizontal" v-show="showAndroid">
        <ion-row>
          <ion-col size="12">
            <ion-text class="help_text">
              Cliccando sul banner che compare a fondo pagina oppure andando nel menù e cliccando su <span class="text_bold">"Installa app"</span> si aprirà una schermata in cui potrai
              installare l'applicazione selezionando <span class="text_bold">"Installa"</span>. <br />
              La procedura utilizza Google Chrome come browser di riferimento.
            </ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <img src="assets/pwa_instructions/android/step1_only_banner.png" class="img_step1" />
          </ion-col>
          <ion-col size="12">
            <div class="img_container">
              <img src="assets/pwa_instructions/android/step1_menu.png" class="img_step2" />
            </div>
          </ion-col>
          <ion-col size="12">
            <div class="img_container">
              <img src="assets/pwa_instructions/android/step2_popup.png" class="img_step2" />
            </div>
          </ion-col>
          <ion-col size="12">
            <div @click="closeModal" class="btn_close">
              Ok, ho capito
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  modalController,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  //IonImg,
  getPlatforms,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { arrowBackOutline } from "ionicons/icons";

const showIos = ref(false);
const showAndroid = ref(false);

const platforms = getPlatforms();

if (platforms.includes("android")) {
  showAndroid.value = true;
} else if (platforms.includes("ios") || platforms.includes("iphone")) {
  showIos.value = true;
}

/**
 *
 * Handle close modal by clicking on Chiudi button
 *
 */
const closeModal = () => {
  modalController.dismiss();
};
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-button {
    --color: #ffffff;
}


.img_container {
  display: flex;
  justify-content: center;
}

.img_step1 {
  margin-bottom: 10px;
}

.img_step2 {
  height: 400px;
  max-width: 100%;
}

.help_text {
  font-size: 14px;
}

.btn_close {
  margin-top: 24px;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  text-align: center;
  color: #ffffff;
  background-color: #1C90CF;
  box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
  transition: all 0.15s ease-in;
}

.text_bold {
  font-weight: 600;
}
</style>
